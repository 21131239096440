import React from 'react';
import OktaSignInWidget from '../OktaSignInWidget';
import { useOktaAuth } from '@okta/okta-react';

export default function PostAuthorize(defaultProps) {
  const { authState } = useOktaAuth();

  const accessToken = authState.accessToken.accessToken;
  const idToken = authState.idToken.idToken;
  const postAuthnConfigs = { ...defaultProps, accessToken, idToken };

  return (<OktaSignInWidget {...postAuthnConfigs} />);
}
