import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { logKeysProbeFailure } from './lib/amplitude-utils';
import { flushLogs } from './lib/logger';


export default function KeysProber() {
  const {  oktaAuth, authState } = useOktaAuth();
  useEffect(() => {
    async function verifyToken() {
      try {
        await oktaAuth.token.verify(authState.idToken, { ignoreSignature: false });
      } catch (err) {
        await Promise.all([ flushLogs(), logKeysProbeFailure() ]);
      }
    }
    verifyToken();
  }, []);

  return <></>;
}

