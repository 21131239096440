import React from 'react';
import 'url-search-params-polyfill';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import amplitude from 'amplitude-js';
import config from './lib/config';
import sessionStorageUtil from './lib/session-storage-util';

const url = sessionStorageUtil.getOriginalAuthUrl() || window.location.href;
const userId = new URL(url).searchParams.get('amplitudeUserId');
const deviceId = new URL(url).searchParams.get('deviceId');
const amplitudeDeviceId = new URL(url).searchParams.get('amplitude_device_id');

amplitude.getInstance().init(config.amplitudeApiKey, userId, {
  deviceId: amplitudeDeviceId ? amplitudeDeviceId : deviceId,
});

const root = createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
