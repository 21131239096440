import { logAmplitude } from '../../lib/amplitude-utils';

const AMPLITUDE_EVENT = {
  LOAD_IFRAME_SUCCESS: 'LoadIframe::Success',
  LOAD_IFRAME_FAILURE: 'LoadIframe::Failure',
};

const amplitudeLogEvent = {
  eventName: AMPLITUDE_EVENT.LOAD_IFRAME_SUCCESS,
  eventProperties: {
    loginFrameUrl: null,
    message: null,
  },
  callback: () => {},
};

/**
 * Loads the identity login frame which syncs the user okta sessiom
 * @param {String} loginFrameUrl - Identity login frame url
 * @param {String} oktaUrl - Okta issuer url
 */
export async function loadAndSyncSession({ loginFrameUrl, oktaUrl }) {
  try {
    const iframe = document.createElement('iframe');
    iframe.src = `${loginFrameUrl}/index.html?issuer=${oktaUrl}`;
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    await _waitForSessionEstablishedEvent(loginFrameUrl);
  } catch (error) {
    const logEvent = Object.assign(
      {}, amplitudeLogEvent,
      { eventName: AMPLITUDE_EVENT.LOAD_IFRAME_FAILURE,
        eventProperties: { loginFrameUrl,
          message: `Error occurred while loading iframe: ${error.message ? error.message : JSON.stringify(error)}` },
      }
    );
    logAmplitude(logEvent);
  }
}

function _waitForSessionEstablishedEvent(loginFrameUrl){
  return new Promise((resolve) => {
    const logEvent = Object.assign({}, amplitudeLogEvent, { eventProperties: { loginFrameUrl, message: 'Session established successfully after loading the iframe.' } } );
    let timeout = undefined;
    const listener = (event) => {
      const { data: { type } } = event;
      if (type === 'SESSION_ESTABLISHED') {
        clearTimeout(timeout);
        window.removeEventListener('message', listener);
        logAmplitude(logEvent);
        resolve();
      }
    };

    timeout = setTimeout(() => {
      window.removeEventListener('message', listener);
      logEvent.eventName = AMPLITUDE_EVENT.LOAD_IFRAME_FAILURE;
      logEvent.eventProperties.message = 'Error occurred while loading iframe: Iframe did not load in 1 second.';
      logAmplitude(logEvent);
      resolve();
    }, 1000);

    window.addEventListener('message', listener);
  });
}
