const originalAuthUrl = 'originalAuthUrl';
const ATHENA_OAUTH_STATE_KEY = 'athena-oauth-state';

const sessionStorageUtil = {
  getOriginalAuthUrl: () => {
    return sessionStorage.getItem(originalAuthUrl);
  },
  updateOriginalAuthUrl: (value) => {
    sessionStorage.setItem(originalAuthUrl, value);
  },
  removeOriginalAuthUrl: () => {
    sessionStorage.removeItem(originalAuthUrl);
  },
  setLoginState(state) {
    sessionStorage.setItem(ATHENA_OAUTH_STATE_KEY, state);
  },
  getLoginState() {
    return sessionStorage.getItem(ATHENA_OAUTH_STATE_KEY);
  },
};
export default sessionStorageUtil;
