export function treatAsJSON(value) {
  return value ? JSON.parse(value) : undefined;
}
/**
 * decode JWT client-side
 */
export function decodeJWT(rawToken) {
  const token = {};
  token.header = JSON.parse(window.atob(rawToken.split('.')[0]));
  token.payload = JSON.parse(window.atob(rawToken.split('.')[1]));
  return token;
}
/**
   * Given the server time, figure out based on the local device time how much difference there is.
   * Intended so that local checking of things like the access token expire time will still work if the device's
   * clock differs from the server clock.
   *
   * Logic: If the server time is S, and the token expires at S+30, but the device thinks it's already S+50, then set the
   * expire time to be S+80 (e.g. the offset would be 50 = localTime - serverTime)
   */
export function getDeviceTimeOffset({ serverTime, deviceTime }) {
  const deviceTimeOffset = serverTime ? Math.round((deviceTime - parseInt(serverTime)) / 1000) : 0;
  return deviceTimeOffset;
}

/**
   * Format the access token the way the token manager in the OSW expects
   */
export function formatAccessToken({ accessToken, deviceTimeOffset }) {
  const token = decodeJWT(accessToken);
  return {
    accessToken,
    expiresAt: token.payload.exp + deviceTimeOffset,
    tokenType: 'Bearer',
    scopes: token.payload.scp,
    authorizeUrl: token.payload.iss + '/v1/authorize',
    userinfoUrl: token.payload.iss + '/v1/userinfo',
  };
}

/**
   * Format the id token the way the token manager in the OSW expects
   *
   * The scopes parameter is kind of weird and perhaps unnecessary, but
   * it's set in the OSW, so including to match it in case something in
   * their codebase expects it
   */
export function formatIdToken({ idToken, scopes, deviceTimeOffset }) {
  const token = decodeJWT(idToken);
  return {
    idToken: idToken,
    claims: token.payload,
    expiresAt: token.payload.exp + deviceTimeOffset,
    scopes,
    authorizeUrl: token.payload.iss + '/v1/authorize',
    issuer: token.payload.iss,
    clientId: token.payload.aud,
  };
}
