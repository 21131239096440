import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { performReloginIfAllowed } from './lib/authorize-utils';
import Loader from './Loader';

export default function LoginCallbackError({ error }) {

  const [ errorState, setErrorState ] = useState(undefined);
  useEffect(() => {
    (async function () {
      // https://github.com/okta/okta-auth-js/tree/3d32a216afddee28425a1aaab0e3f655312a8806/lib/errors
      if ([ 'AuthApiError', 'OAuthError', 'AuthSdkError' ].includes(error.name)) {
        const performingRelogin = await performReloginIfAllowed({ cause: error.name, message: error.message });
        if (!performingRelogin) {
          setErrorState(error);
        }
      } else {
        setErrorState(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (errorState){
      throw (errorState);
    }
  }, [ errorState ]);

  return <Loader />;
}

LoginCallbackError.propTypes = {
  error: PropTypes.object,
};
