import config from "./config";
import URI from "urijs";
import axios from "axios";
import axiosRetry, { isRetryableError } from "axios-retry";

const preAuthnEndpoint = new URI(config.iamCoreUrl)
  .segment("api/v1/lwa-config/pre-authn")
  .toString();
const postAuthnEndpoint = new URI(config.iamCoreUrl)
  .segment("api/v1/lwa-config/post-authn")
  .toString();
const manageProfileEndpoint = new URI(config.iamCoreUrl)
  .segment("api/v1/lwa-config/manage-profile")
  .toString();
const processCredsEndpoint = new URI(config.iamCoreUrl)
  .segment("api/v1/process-creds")
  .toString();

function generateOptions(data) {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    data,
  };
}

axiosRetry(axios, {
  retries: 2,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    return (
      isRetryableError(error) ||
      error.response?.status === 429 ||
      error?.code === "ECONNABORTED"
    );
  },
  shouldResetTimeout: true,
});

const fetchWithTimeout = async (authnEndpoint, options, onRetry) => {
  // During registration flow with emailToken, the pre-authn API is very slow because it needs to create
  // the user in Okta and can take upto 20 seconds to respond. If we retry the request too soon, before the user
  // creation completes, Okta throws errors and the logic assumes the emailToken is not valid. So, for now,
  // increasing the timeout to 25 seconds so we don't break the registration flow
  try {
    const response = await axios({
      url: authnEndpoint,
      ...options,
      onRetry,
      timeout: 30000,
    });
    return response.data;
  } catch (err) {
    const data = err.response?.data;
    const error = new Error(data?.message || err.message);
    error.statusCode = err.response?.status;
    error.redirectable = data?.redirectable;
    error.isBackendError = true;
    throw error;
  }
};

export default {
  fetchPreAuthnConfig: async (data) => {
    return fetchWithTimeout(preAuthnEndpoint, generateOptions(data));
  },
  fetchPostAuthnConfig: async (data, accessToken, onRetry = () => {}) => {
    const options = generateOptions(data);
    options.headers.Authorization = `Bearer ${accessToken}`;
    return fetchWithTimeout(postAuthnEndpoint, options, onRetry);
  },
  fetchManageProfileConfig: async (data, onRetry = () => {}) => {
    return fetchWithTimeout(
      manageProfileEndpoint,
      generateOptions(data),
      onRetry
    );
  },
  processCredsApi: async (data) => {
    return fetchWithTimeout(processCredsEndpoint, generateOptions(data));
  },
};
