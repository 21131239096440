import config from './config';
import { getLwaLoginTimes, setLwaLoginCookie } from './cookie-utils';


export const shouldThrottle = (clientId) => {
  //set login time
  const currentTs = Math.round(Date.now() / 1000);
  const lwaLoginTimesCookie = getLwaLoginTimes() || {};
  if (!lwaLoginTimesCookie[clientId]) {
    lwaLoginTimesCookie[clientId] = [ currentTs ];
  } else {
    lwaLoginTimesCookie[clientId].push(currentTs);
  }
  setLwaLoginCookie(lwaLoginTimesCookie);
  return checkIfWeShouldThrottle(lwaLoginTimesCookie[clientId], currentTs);
};

function checkIfWeShouldThrottle(lwaLoginTimesCookie, currentTs) {
  const loginThrottleLimits = config.loginThrottleLimits;
  let throttlePayload = false;
  for (const limitRange of loginThrottleLimits) {
    const maxLogins = limitRange[0];
    const timeFrame = limitRange[1];
    if (lwaLoginTimesCookie.length > maxLogins
      && lwaLoginTimesCookie[lwaLoginTimesCookie.length - maxLogins - 1] > currentTs - timeFrame) {
      throttlePayload = { loginsPerformed: lwaLoginTimesCookie.length, maxLoginsAllowed: maxLogins, timeFrame };
      break;
    }
  }
  return throttlePayload;
}
