import React from 'react';

export default function Loader() {
  return (
    <main data-se="auth-container" tabIndex="-1" id="okta-sign-in" className="auth-container main-container no-beacon"
      style={{ display: 'block' }}>
      <div className="okta-sign-in-header auth-header" role="banner">
        <div data-type="beacon-container" className="beacon-container beacon-small beacon-loading"
          style={{ transform: 'scale(1, 1)', textIndent: '1px' }}>
          <div>
            <div className="beacon-blank"></div>
            <div className="bg-helper auth-beacon auth-beacon-security" data-se="loading-beacon">
              <div className="okta-sign-in-beacon-border auth-beacon-border js-auth-beacon-border"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="auth-content" role="main">
        <div className="auth-content-inner">
          <div>
            <div slot="content">
              <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                <div className="o-form-error-container" data-se="o-form-error-container"></div>
                <div className="o-form-fieldset-container" data-se="o-form-fieldset-container"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
