import React from 'react';
import iamCoreClient from './lib/iam-core-client';
import OktaSignInWidget from './OktaSignInWidget';
import { useOktaAuth } from '@okta/okta-react';
import ThrottleError from './ThrottleError';
import { shouldThrottle } from './lib/throttle-utils';
import Loader from './Loader';
import sessionStorageUtil from './lib/session-storage-util';
import { logPostAuthErrorEvent, logThrottleErrorEvent } from './lib/amplitude-utils';
import { enrichApiResponse, parsedPayload, performReloginIfAllowed, validateState } from './lib/authorize-utils';
import { useQuery } from './lib/router-utils';
import { useFramingContext } from './FrameRestricter';
import { ValidationError } from './lib/errors';
import KeysProber from './KeysProber';

const POST_AUTHN_DEGRADE_REQUEST = 'postAuthnDegradeRequest';

function _fetchData( postAuthnInfo, accessToken ) {
  //swagger 2.0 doesn't like nulls'
  postAuthnInfo = Object.fromEntries(Object.entries(postAuthnInfo).filter(([ _, v ]) => v != null));
  return iamCoreClient.fetchPostAuthnConfig({ postAuthnInfo }, accessToken);
}
export default function PostAuthorize() {
  const { authState } = useOktaAuth();
  const [ postAuthnConfigs, setPostAuthnConfigs ] = React.useState();
  const [ postAuthnConfigFetchError, setPostAuthnConfigFetchError ] = React.useState();
  const { allowFraming } = useFramingContext();

  const searchParams = useQuery();
  const payload = parsedPayload(searchParams);
  const {
    clientId,
    scopeString,
    redirectUri,
    aud,
    emailToken,
    registrationToken,
    idp,
    fhir,
    authServerId,
    lwaUniqId,
    uiLocales,
    skipPatientSummary,
    customData,
  } = payload;
  const [ isThrottled, setIsThrottled ] = React.useState(false);
  const [ postAuthnWorkflowError, setPostAuthnWorkflowError ] = React.useState();

  React.useEffect(() => {

    async function setup() {
      try {
        const throttlePayload = shouldThrottle(clientId);
        if (!!throttlePayload) {
          logThrottleErrorEvent(
            {
              ...throttlePayload, userId: authState.accessToken.claims.uid,
              email: authState.accessToken.claims.sub,
            });
          setIsThrottled(true);
        } else {
          const loginState = sessionStorageUtil.getLoginState();
          const parsedState = JSON.parse(loginState);

          const stateValidated = validateState({ parsedState });
          if (!stateValidated){
            const performingRelogin = await performReloginIfAllowed({ cause: 'state mismatch' });
            if (!performingRelogin){
              throw new ValidationError('Unauthorized');
            }
          }
          const { accessToken: { accessToken }, idToken: { idToken } } = authState;
          const postAuthnRequest = {
            clientId,
            scope: scopeString,
            redirectUri,
            aud,
            emailToken,
            registrationToken,
            idp,
            fhir,
            authServerId,
            lwaUniqId,
            uiLocales,
            skipPatientSummary,
            customData,
            idToken,
          };

          const apiResponse = await _fetchData(postAuthnRequest, accessToken);
          const configs = enrichApiResponse({
            apiResponse,
            parsedPayload: payload,
          });
          // We never want to prompt again in post authorize as it will ask to perform signin again.
          delete configs.prompt;

          if (configs.idp) {
            // we're going to immediately redirect through to okta's idp authorization flow via the signin-widget
            // without any user interaction so we dont care about being iframe'd for federated idp flows
            allowFraming();
          }
          setPostAuthnConfigs({
            ...configs,
            accessToken,
            idToken,
          });
        }

      } catch (err) {
        setPostAuthnConfigFetchError(err);
      }
    }

    setup();
  }, []);

  React.useEffect(() => {
    if (postAuthnConfigFetchError){
      // eslint-disable-next-line max-len, camelcase
      logPostAuthErrorEvent({ error_message: postAuthnConfigFetchError.message, userId: authState.accessToken.claims.uid });
      throw (postAuthnConfigFetchError);
    }
  }, [ postAuthnConfigFetchError ]);

  React.useEffect(() => {
    const handlePostAuthnDegradeRequest = (event) => {
      setPostAuthnWorkflowError(event.detail);
    };
    window.addEventListener(POST_AUTHN_DEGRADE_REQUEST, handlePostAuthnDegradeRequest);
    return () => {
      window.removeEventListener(POST_AUTHN_DEGRADE_REQUEST, handlePostAuthnDegradeRequest);
    };
  }, []);
  if (postAuthnWorkflowError) throw new Error(JSON.stringify(postAuthnWorkflowError));
  if (isThrottled) return <ThrottleError />;
  if (!postAuthnConfigs) return <Loader />;
  return (<>
    <OktaSignInWidget {...postAuthnConfigs} />
    <KeysProber></KeysProber>
  </>);

}
