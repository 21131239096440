import React from 'react';
import sessionStorageUtil from '../lib/session-storage-util';
import { ValidationError } from '../lib/errors';
import { removeLoginCookies } from '../lib/cookie-utils';
import Loader from '../Loader';
import PropTypes from 'prop-types';
import { OktaAuth } from '@okta/okta-auth-js';
import URI from 'urijs';

const DEFAULT_DUMMY_POST_LOGOUT_URL = 'TO_BE_REMOVED';

export default function OIDCLogout({ oktaAuth }) {
  const [ logoutError, setIsLogoutError ] = React.useState();

  const originalGetSignOutRedirectUrl = oktaAuth.getSignOutRedirectUrl.bind(oktaAuth);

  //Okta SDK puts a default post_logout_redirect_uri which we don't want
  //So, pass a dummy value and remove it after the URL has been calculated
  oktaAuth.getSignOutRedirectUrl = (options) => {
    const logOutUri = originalGetSignOutRedirectUrl(options);
    const parsedLogoutUri = new URI(logOutUri);
    parsedLogoutUri.removeQuery('post_logout_redirect_uri', DEFAULT_DUMMY_POST_LOGOUT_URL);
    return parsedLogoutUri.toString();
  };

  async function oidcLogout(){
    const queryParams = new URLSearchParams(window.location.search);
    const logoutQueryParams = {
      idToken: queryParams.get('id_token_hint'),
      redirectUri: queryParams.get('post_logout_redirect_uri'),
      state: queryParams.get('state'),
    };

    if (!logoutQueryParams.idToken) {
      throw new ValidationError('Error: Missing id_token_hint');
    }

    await oktaAuth.signOut({
      idToken: { idToken: logoutQueryParams.idToken }, //api extracts idToken from object
      postLogoutRedirectUri: logoutQueryParams.redirectUri || DEFAULT_DUMMY_POST_LOGOUT_URL,
      state: logoutQueryParams.state,
    });
  }

  React.useEffect(() => {
    async function logoutSetup() {
      removeLoginCookies();
      sessionStorageUtil.removeOriginalAuthUrl();
      try {
        await oidcLogout();
      } catch (error){
        setIsLogoutError(error);
      }
    }
    logoutSetup();
  }, []);

  React.useEffect(() => {
    if (logoutError){
      throw (logoutError);
    }
  }, [ logoutError ]);

  return <Loader />;
}

OIDCLogout.propTypes = {
  oktaAuth: PropTypes.instanceOf(OktaAuth).isRequired,
};
