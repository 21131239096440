const signinWidget = require('../signin-widget');

export function init(options, oktaAuth) {
  const oktaSignIn = signinWidget.createWidget(options);
  // If creating a new account, we need to start on the account creation page instead of the login page
  if (options.createAccount && !options.stateToken) {
    window.location.hash = 'create';
  }
  // If a user hits Okta's authn endpoint directly through their browser, and then we
  // send them through Okta's authorize, the new session will win.  However, if
  // another app validates a user's password by hitting Okta's authn on the back end,
  // then sends them through authorize with the new sessionToken, if the browser has
  // an existing session for another user, that existing session will win over the
  // new sessionToken.  If a sessionToken was specified as a parameter of this
  // page, the intention is to log in the user corresponding to that token, regardless
  // of who Okta thinks is currently logged in.  Therefore delete any existing session
  // before authorizing the user.
  if (options.sessionToken) {
    oktaSignIn.signOut(() => {
      window.location = options.authorizeRedirectUrl;
    });
  } else {
    const forceLogin = options.prompt && options.prompt.match(/login/);
    signinWidget.renderLogin({
      oktaSignIn,
      authorizeRedirectUrl: options.authorizeRedirectUrl,
      forceLogin,
      oktaAuth,
    });
  }
  window.oktaSignIn = oktaSignIn;
}

