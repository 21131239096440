import React from 'react';
import OktaSignInWidget from '../OktaSignInWidget';
import iamCoreClient from '../lib/iam-core-client';
import Loader from '../Loader';

export default function PreAuthorize(defaultProps) {
  const [ manageProfileConfigs, setManageProfileConfigs ] = React.useState();
  const [ error, setIsError ] = React.useState();
  const { clientId } = defaultProps;

  function _fetchData(manageProfileInfo) {
    return iamCoreClient.fetchManageProfileConfig({ manageProfileInfo });
  }

  React.useEffect(() => {
    async function setManageProfile() {
      let apiResponse;
      try {
        const manageProfilePayload = { clientId };
        apiResponse = await _fetchData(manageProfilePayload);
        setManageProfileConfigs({ ...defaultProps, ...apiResponse });
      } catch (error) {
        if (error.statusCode === 400) {
          setIsError(error);
        } else {
          setManageProfileConfigs({ ...defaultProps, manageProfileError: true });
        }
      }
    }
    setManageProfile();
  }, []);

  React.useEffect(() => {
    if (error) {
      throw (error);
    }
  }, [ error ]);


  if (manageProfileConfigs) {
    return (<OktaSignInWidget {...manageProfileConfigs} />);
  }
  return <Loader />;
}
