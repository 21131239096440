import React from 'react';

export default function ThrottleError(){

  return (
    <div>Try logging in again in a couple of minutes. Use your browser’s Back button to return to the previous page.
      <br/><br/>
      If you&apos;re trying to contact a healthcare provider&apos;s office, please call them directly.
      <br/><br/>
      Dial 911 in the case of a medical emergency.
    </div>
  );
}
