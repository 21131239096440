import React from 'react';
import PropTypes from 'prop-types';

const FramingContext = React.createContext({});

export const useFramingContext = () => ( React.useContext(FramingContext) );

export const FrameRestricter = ({ children }) => {

  const [ displayBody, setIsDisplayBody ] = React.useState(window.self === window.top);
  const [ framingAllowed, setIsFramingAllowed ] = React.useState(false);
  React.useEffect( () => {
    if (window.self === window.top || framingAllowed ){
      setIsDisplayBody(true);
    }
  }, [ framingAllowed ]);

  return (
    <div style={{ display: displayBody ? 'block' : 'none' }}>
      <FramingContext.Provider value= {{ allowFraming: () => {
        setIsFramingAllowed(true);
      } }} >
        {children}
      </FramingContext.Provider>
    </div>
  );

};

FrameRestricter.propTypes = {
  children: PropTypes.node.isRequired,
};

