import * as signinWidget from '../signin-widget';

export async function init(options, oktaAuth) {
  if (window.location.hash !== '#profile') {
    // TODO: This causes the signin widget to check for an existing session and route to the profile page.
    // It's probably not the best solution to this problem, but it works for profile management
    window.location.hash = 'profile';
  }
  const oktaSignIn = signinWidget.createWidget(options);
  let accessToken;
  if (!options.accessToken){
    const wrappedToken =
      oktaSignIn.authClient.tokenManager ? await oktaSignIn.authClient.tokenManager.get('access_token') : null;
    if (wrappedToken) {
      /**
       * Note that we look at expiresAt from the *wrapped* javascript object defined in the Okta
       *  token manager, and not the exp claim in the JWT token itself.  This relies on the tokenManager
       *  expiresAt to be adjusted based on any for discrepancy between the system and the device time
       *  so that a computer whose clock is set too fast doesn't incorrectly say the token has expired too soon.
       **/
      const expiresAt = wrappedToken['expiresAt'];
      if (expiresAt > Date.now() / 1000) {
        accessToken = wrappedToken['accessToken'];
      }
    }
  }

  signinWidget.renderLogin({
    oktaSignIn,
    authorizeRedirectUrl: options.authorizeRedirectUrl,
    accessToken,
    forceLogin: false,
    oktaAuth,
  });
}
