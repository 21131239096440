import { getOktaAuthInstance } from '../lib/okta-utils';
import { useOktaAuth } from '@okta/okta-react';
import OIDCLogout from './OIDCLogout';
import React from 'react';
import { useParams } from 'react-router';

export default function Logout() {
  const { authServerId } = useParams();
  let oktaAuth;
  if (authServerId) {
    oktaAuth = getOktaAuthInstance({ authServerId });
  } else {
    oktaAuth = useOktaAuth().oktaAuth;
  }
  return <OIDCLogout oktaAuth={oktaAuth} ></OIDCLogout>;
}
