import logger from 'loglevel';
import remote from 'loglevel-plugin-remote';
import config from './config';
import { setAndGetLwaUniqId } from './cookie-utils';

const logMessageFormat = log => ({
  stackTrace: log.stacktrace,
  level: log.level.label,
  message: log.message,
  lwaUniqId: setAndGetLwaUniqId(),
});

remote.apply(logger, {
  format: logMessageFormat,
  onUnauthorized: () => {},
  url: config.loggingService,
  level: 'info',
  backoff: {
    multiplier: 2,
    jitter: 0.1,
    limit: 30000,
  },
  stacktrace: {
    levels: [ 'error' ],
    depth: 100,
    excess: 0,
  },
  timeout: 1000,
  // needs to be set so that the first log doesn't get flushed separately
  token: undefined,
});

export function flushLogs(){
  return new Promise( resolve => {
    remote.setToken('Flush Logs');
    setTimeout(resolve, 300);
  });
}

export { logger };
