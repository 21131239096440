export default Object.freeze({
  AUTHN_WORKFLOWS: {
    AUTHORIZE: 'Authorize',
    FHIRAUTHORIZE: 'FhirAuthorize',
    AUTHORIZEVIAIDP: 'AuthorizeViaIDP',
    MANAGEPROFILE: 'ManageProfile',
  },
  ATHENA_OAUTH_STATE_COOKIE: 'athena-oauth-state-spa',
  LOGIN_CONTAINER_ID: 'okta-login-container',
});
