import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import PreAuthorize from './PreAuthorize';
import PostAuthorize from './PostAuthorize';
import Loader from './Loader';

export default function Authorize() {
  const { authState } = useOktaAuth();

  if (!authState) return <Loader />;
  if (authState.isAuthenticated) {
    return <PostAuthorize/>;
  } else  {
    return (<PreAuthorize/>);
  }
}
