import config from './config';
import URI from 'urijs';
import { createOktaAuth } from '@athena/identity-okta-auth-js';
import { logger } from './logger';

function getErrorDetails(error) {
  return `error occurred while making a request to Okta with error ${error.message}, status ${error.response?.status} statusText ${error.response?.statusText} to URL ${error.config?.url} with response headers ${JSON.stringify(error.response?.headers)}`;
}

function getIssuer({ authServerId }) {
  authServerId = authServerId || config.primaryAuthorizationServer.id;
  const { baseUrl } = config.primaryAuthorizationServer;
  const parsedUri = new URI(baseUrl);
  return parsedUri.segment(authServerId).toString();
}

export const getOktaAuthInstance = ({ authServerId } = {}) => {
  const issuer = getIssuer({ authServerId });
  return createOktaAuth({
    clientId: config.oauthClientId,
    redirectUri: config.redirectUri,
    scopes: config.scopes,
    issuer,
    pkce: true,
    ignoreLifetime: true,
    transactionManager: {
      enableSharedStorage: false,
    },
    cookies: {
      secure: true,
    },
    storageManager: {
      token: {
        storageType: 'memory',
      },
    },
    ignoreSignature: true,
    useCustomHttpRequestClient: true,
    onRetry: (error) => {
      logger.error(`A retryable ${getErrorDetails(error)}`, error);
    },
    errorHandler: (error) => {
      logger.error(`A fatal ${getErrorDetails(error)}`, error);
    },
  });
};
