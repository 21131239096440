import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import { useQuery } from './lib/router-utils';
import React from 'react';
import Loader from './Loader';

export default function Logout() {
  const query = useQuery();
  const redirectUri = query.get('redirect_uri');
  const { oktaAuth } = useOktaAuth();
  const [ logoutMsg, setLogoutMsg ] = useState('');
  const [ redirectToUrl, setRedirectToUrl ] = useState();

  React.useEffect(() => {
    async function performLogout() {
      try {
        await oktaAuth.closeSession();
        setLogoutMsg('Logged out!');
      } catch (err) {
        setLogoutMsg(err.toString());
      }
      if (redirectUri) {
        setRedirectToUrl(redirectUri);
      }
    }
    performLogout();
  }, []);

  if (redirectToUrl) window.location.assign(redirectToUrl);
  if (logoutMsg) return (<div id='logout-message'>{logoutMsg}</div>);
  return <Loader/>;
}
