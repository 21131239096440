/* eslint-disable camelcase */
import React from 'react';
import iamCoreClient from './lib/iam-core-client';
import OktaSignInWidget from './OktaSignInWidget';
import sessionStorageUtil from './lib/session-storage-util';
import Loader from './Loader';
import { logSpaLandingEvent, logPreAuthErrorEvent } from './lib/amplitude-utils';
import { RedirectableError, ValidationError } from './lib/errors';
import { enrichApiResponse, parsedPayload, getUsernameFromSession } from './lib/authorize-utils';
import { useQuery } from './lib/router-utils';
import { initAuthorize } from './lib/scripts/page-specific/oauth2/authorize';
import { useFramingContext } from './FrameRestricter';
import { useOktaAuth } from '@okta/okta-react';
import { recordAuthorizeUrl } from './lib/cookie-utils';

export default function PreAuthorize() {

  const [ preAuthnConfigs, setPreAuthnConfigs ] = React.useState();
  const [ error, setIsError ] = React.useState();
  const [ authorizeToOktaUrl, setAuthorizeToOktaUrl ] = React.useState();
  const searchParams = useQuery();
  const { oktaAuth } = useOktaAuth();
  const payload = parsedPayload(searchParams);
  const { allowFraming } = useFramingContext();
  const {
    isFhirEhrLaunch,
    clientId,
    scopeString,
    redirectUri,
    aud,
    emailToken,
    registrationToken,
    state,
    idp,
    authorizeRedirectUrl,
    fhir,
    authServerId,
    responseType,
    prompt,
    sessionToken,
    lwaUniqId,
    uiLocales,
    skipPatientSummary,
    customData,
    hideRememberMe,
  } = payload;

  function _fetchData(preAuthnInfo) {
    //swagger 2.0 doesn't like nulls
    preAuthnInfo = Object.fromEntries(Object.entries(preAuthnInfo).filter(([ _, v ]) => v != null));
    return iamCoreClient.fetchPreAuthnConfig({ preAuthnInfo });
  }

  React.useEffect(() => {
    async function setPreAuthn() {
      try {
        recordAuthorizeUrl(window.location.href);
        sessionStorageUtil.updateOriginalAuthUrl(window.location.href);
        await logSpaLandingEvent({ client_id: clientId });

        function performValidations() {
          if (!clientId) {
            throw new ValidationError('Error: Missing client_id query parameter. You must provide a client_id.');
          }
          if (!redirectUri) {
            throw new ValidationError('Error: Missing redirect_uri in the request_url. A redirect_uri must be provided in the request_url.');
          }
          try {
            new URL(redirectUri);
          } catch (err) {
            throw new ValidationError('Error: Malformed redirect_uri. The redirect_uri param must be a valid URL.');
          }
        }

        function performPostResponseValidations() {
          if (!scopeString || !state || !responseType) {
            throw new RedirectableError('Error: Missing one or more of required query parameters: scope, state, and response_type', redirectUri);
          }
          if (fhir && !aud) {
            throw new RedirectableError('Error: Missing required aud query parameter.', redirectUri);
          }
        }

        performValidations();
        if ((prompt === 'none' && !sessionToken)) {
          setAuthorizeToOktaUrl(authorizeRedirectUrl.toString());
        } else {
          const preAuthnRequest = {
            clientId,
            scope: scopeString,
            redirectUri,
            aud,
            emailToken,
            registrationToken,
            idp,
            fhir,
            authServerId,
            lwaUniqId,
            uiLocales,
            skipPatientSummary,
            customData,
            hideRememberMe,
          };

          if (emailToken || registrationToken){
            const loggedInUsername = await getUsernameFromSession(oktaAuth);
            preAuthnRequest.loggedInUsername = loggedInUsername;
          }
          const apiResponse = await _fetchData(preAuthnRequest);

          const configs = enrichApiResponse({ apiResponse, parsedPayload: payload });
          const finalIdp = configs.idp;

          //There is no custom workflow for SMART on FHIR, redirect directly to Okta
          if (isFhirEhrLaunch) {
            if (finalIdp) {
              authorizeRedirectUrl.removeQuery('idp').addQuery({ idp: finalIdp });
            }
            setAuthorizeToOktaUrl(authorizeRedirectUrl.toString());
          }
          if (finalIdp){
            // we're going to immediately redirect through to okta's idp authorization flow via the signin-widget
            // without any user interaction so we dont care about being iframe'd for federated idp flows
            allowFraming();
          }
          performPostResponseValidations();
          setPreAuthnConfigs(configs);
        }
      } catch (error) {
        await logPreAuthErrorEvent({ error_message: error.message });
        if (error.message && error.redirectable) {
          setIsError(new RedirectableError(error.message, redirectUri));
        } else {
          setIsError(error);
        }
      }
    }
    setPreAuthn();
  }, []);

  React.useEffect(() => {
    if (error){
      throw (error);
    }
  }, [ error ]);

  if (authorizeToOktaUrl) {
    window.location.assign(authorizeToOktaUrl);
    return null;
  }
  if (preAuthnConfigs) return (<OktaSignInWidget {...preAuthnConfigs} renderWidgetFunction={initAuthorize}/>);
  return <Loader />;
}
